/** @jsx jsx */
import { jsx } from "theme-ui";
import { graphql, useStaticQuery, Link } from "gatsby";
import { Container, Row, Col } from "react-bootstrap";
import Button from "../../../components/shared/button";
import Social, { SocialLink } from "../../../components/social";
import { StaticImage } from "gatsby-plugin-image";
import {
    FooterWrap,
    FooterTopArea,
    FooterWidget,
    FooterDec,
    FooterLogo,
    FooterMenuWidget,
    SingleFooterMenu,
    FooterWidgetTitle,
    WidgetTitle,
    FooterWidgetMenuList,
    NavItem,
    FooterBottomArea,
    FooterBottomInner,
    CopyrightText,
    ButtonRightBox,
} from "./style";

const FooterTwo = () => {
    const footerTwoQuery = useStaticQuery(graphql`
        query FooterTwoQuery {
            footerJson {
                id
                quickLink {
                    path
                    text
                }

                footerAbout
            }

            allStrapiCategory(limit: 4) {
                edges {
                    node {
                        name
                        slug
                    }
                }
            }
        }
    `);
    const { footerAbout, quickLink } = footerTwoQuery.footerJson;

    return (
        <FooterWrap>
            <FooterTopArea>
                <Container className="container">
                    <Row>
                        <Col
                            lg={{ span: 3, order: 1 }}
                            md={{ span: 6, order: 1 }}
                            sm={{ span: 6, order: 1 }}
                        >
                            <FooterWidget>
                                <FooterLogo>
                                    <Link to="https://www.indusvalleylabs.com/">
                                        <StaticImage
                                            src="../../../data/images/logo/logo_1.png"
                                            alt=""
                                        />
                                    </Link>
                                </FooterLogo>
                                <FooterDec>{footerAbout}</FooterDec>
                            </FooterWidget>
                        </Col>

                        <Col
                            lg={{ span: 6, order: 2 }}
                            md={{ span: 12, order: 3 }}
                            sm={{ span: 12, order: 3 }}
                        >
                            <FooterMenuWidget
                                sx={{
                                    ml: ["0px", "0px", "0px", "20px", "40px"],
                                }}
                            >
                                <SingleFooterMenu>
                                    <FooterWidgetTitle>
                                        <WidgetTitle>Company</WidgetTitle>
                                    </FooterWidgetTitle>

                                    <FooterWidgetMenuList>
                                        {quickLink &&
                                            quickLink.map((linkItem, i) => (
                                                <NavItem key={`id-${i}-one`}>
                                                    <Link to={linkItem.path}>
                                                        {linkItem.text}
                                                    </Link>
                                                </NavItem>
                                            ))}
                                    </FooterWidgetMenuList>
                                </SingleFooterMenu>
                                <SingleFooterMenu>
                                    <FooterWidgetTitle>
                                        <WidgetTitle>Category</WidgetTitle>
                                    </FooterWidgetTitle>

                                    <FooterWidgetMenuList>
                                        {footerTwoQuery.allStrapiCategory
                                            .edges &&
                                            footerTwoQuery.allStrapiCategory.edges.map(
                                                (linkItem, i) => (
                                                    <NavItem
                                                        key={`id-${i}-one`}
                                                    >
                                                        <Link
                                                            to={`/category/${linkItem.node.slug}/`}
                                                        >
                                                            {linkItem.node.name}
                                                        </Link>
                                                    </NavItem>
                                                )
                                            )}
                                    </FooterWidgetMenuList>
                                </SingleFooterMenu>
                            </FooterMenuWidget>
                        </Col>

                        <Col
                            lg={{ span: 3, order: 3 }}
                            md={{ span: 6, order: 2 }}
                            sm={{ span: 6, order: 2 }}
                        >
                            <Social
                                sx={{ mt: "20px" }}
                                shape="rounded-5"
                                space={15}
                                bgColor="themetwo"
                            >
                                <SocialLink href="https://www.facebook.com/indusvalleylabs">
                                    <i className="icofont-facebook"></i>
                                </SocialLink>

                                <SocialLink href="https://www.linkedin.com/company/indus-valley-labs-pvt-ltd/mycompany/">
                                    <i className="icofont-linkedin"></i>
                                </SocialLink>
                            </Social>
                            <ButtonRightBox sx={{ mt: "20px" }}>
                                <Button
                                    sx={{ color: "#fff" }}
                                    path="https://www.indusvalleylabs.com/contact"
                                    size="large"
                                    shape="rounded-10"
                                >
                                    {" "}
                                    Share your thinking{" "}
                                    <i className="icofont-long-arrow-right"></i>
                                </Button>
                            </ButtonRightBox>
                        </Col>
                    </Row>
                </Container>
            </FooterTopArea>
            <FooterBottomArea
                sx={{
                    textAlign: "center",
                    borderTop: "1px solid rgba(255, 255, 255, 0.2)",
                }}
            >
                <Container>
                    <Row>
                        <Col xs={12}>
                            <FooterBottomInner>
                                <CopyrightText>
                                    &copy; Copyrights
                                    <a
                                        href="https://www.indusvalleylabs.com/"
                                        target="_blank"
                                        rel="noopener noreferrer"
                                    >
                                        {" "}
                                        Indus Valley Labs{" "}
                                    </a>
                                    {new Date().getFullYear()}. All rights
                                    reserved.
                                </CopyrightText>
                            </FooterBottomInner>
                        </Col>
                    </Row>
                </Container>
            </FooterBottomArea>
        </FooterWrap>
    );
};

export default FooterTwo;
